import { Component } from "react";

class Input extends Component {
  render() {
    const firstLetter = this.props.name.slice(0, 1).toUpperCase();
    const restOfString = this.props.name.slice(1);

    const displayName = firstLetter + restOfString;

    return (
      <div className="field mb-3">
        <label htmlFor={this.props.name} className="label">{displayName}:</label>
        <div className="control">
          <input
            className="input"
            id={this.props.name}
            name={this.props.name}
            type={this.props.inputType}
            value={this.props.inputValue}
            onChange={this.props.handleEdit}
          />
        </div>
      </div>
    );
  }
}

export default Input;