import { Component } from "react";
import Input from "./inputComponent.jsx";
import Select from "./selectComponent.jsx";

class Strategy1AddDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      index: "BANKNIFTY",
      expiry: "2024-01-01",
      expiryType: "Weekly",
      lots: 1,
      tradeType: "SELL",
      timeframe: "5minute",
      candleType: "NORMAL",
      conditionType: "BOTH",
      lookbackPeriod: 3,
      emaLength: 5,
      vidyaLength: 9,
      macdFastLength: 12,
      macdSlowLength: 26,
      macdSignalLength: 9,
      UTBotSensitivity: 1,
      UTBotATRLength: 10,
      toTradeStraddle: "No",
      slPct: 0,
      straddleSLPct: 0,
      tradingMode: "Paper"
    }
  }

  componentDidMount() {
    let localStorageState = localStorage.getItem("project-resonance-inputs");

    if (localStorageState === undefined || localStorageState === null || localStorageState === "") {
      let stateToSave = JSON.stringify(this.state);
      localStorage.setItem("project-resonance-inputs", stateToSave);

    } else {
      localStorageState = JSON.parse(localStorageState);
      this.setState(localStorageState);
    }
  }

  componentDidUpdate() {
    console.log(this.state);
  }

  handleEdit = async e => {
    const name = e.target.name;
    const value = e.target.value;

    this.setState(prevState => {
      const newState = { ...prevState };
      newState[name] = value;
      return newState;
    }, () => {
      let stateToSave = JSON.stringify(this.state);
      localStorage.setItem("project-resonance-inputs", stateToSave);
    });
  }

  handleCheckboxEdit = async e => {
    const name = e.target.name;
    const checked = e.target.checked;

    console.log(checked);

    this.setState(prevState => {
      const newState = { ...prevState };
      newState[name] = checked;
      return newState;
    }, () => {
      let stateToSave = JSON.stringify(this.state);
      localStorage.setItem("project-resonance-inputs", stateToSave);
    });

    console.log(localStorage.getItem("project-resonance-inputs"));
  }

  handleAdd = async () => {
    await this.props.addInstance(this.state, 1);
    this.props.hideAddDialog();
  }

  render() {
    return (
      <div className={this.props.open ? "modal is-active" : "modal"}>
        <div className="modal-background" onClick={this.props.hideAddDialog}></div>

        <div className="modal-card is-fullwidth">
          <header className="modal-card-head">
            <h5 className="modal-card-title">Add Instance</h5>
            <button className="delete" onClick={this.props.hideAddDialog}></button>
          </header>

          <section className="modal-card-body">
            <p className="subtitle">Add a new instance which will run its seperate strategy instance</p>

            <form onSubmit={this.handleAdd}>
              <Select
                name="index"
                handleEdit={this.handleEdit}
                inputValue={this.state.index}
                options={["NIFTY", "BANKNIFTY", "FINNIFTY", "MIDCPNIFTY", "SENSEX"]}
              />

              <Input
                name="expiry"
                inputType="date"
                handleEdit={this.handleEdit}
                inputValue={this.state.expiry}
              />

              <Select
                name="expiryType"
                handleEdit={this.handleEdit}
                inputValue={this.state.expiryType}
                options={["Weekly", "Monthly"]}
              />

              <Input
                name="lots"
                inputType="number"
                inputValue={this.state.lots}
                handleEdit={this.handleEdit}
              />

              <Select
                name="tradeType"
                options={["BUY", "SELL"]}
                handleEdit={this.handleEdit}
                inputValue={this.state.tradeType}
              />

              <Select
                name="timeframe"
                handleEdit={this.handleEdit}
                inputValue={this.state.timeframe}
                options={["minute", "3minute", "5minute", "10minute", "15minute", "30minute", "60minute"]}
              />

              <Select
                name="candleType"
                options={["NORMAL", "HA"]}
                handleEdit={this.handleEdit}
                inputValue={this.state.candleType}
              />

              <Select
                name="conditionType"
                options={["BARSUM", "VIDYAEMA", "BOTH", "MACDUT"]}
                handleEdit={this.handleEdit}
                inputValue={this.state.conditionType}
              />

              <Input
                inputType="number"
                name="lookbackPeriod"
                handleEdit={this.handleEdit}
                inputValue={this.state.lookbackPeriod}
              />

              <Input
                name="emaLength"
                inputType="number"
                handleEdit={this.handleEdit}
                inputValue={this.state.emaLength}
              />

              <Input
                name="vidyaLength"
                inputType="number"
                handleEdit={this.handleEdit}
                inputValue={this.state.vidyaLength}
              />

              <Input
                inputType="number"
                name="macdFastLength"
                handleEdit={this.handleEdit}
                inputValue={this.state.macdFastLength}
              />

              <Input
                inputType="number"
                name="macdSlowLength"
                handleEdit={this.handleEdit}
                inputValue={this.state.macdSlowLength}
              />

              <Input
                inputType="number"
                name="macdSignalLength"
                handleEdit={this.handleEdit}
                inputValue={this.state.macdSignalLength}
              />

              <Input
                inputType="number"
                name="UTBotSensitivity"
                handleEdit={this.handleEdit}
                inputValue={this.state.UTBotSensitivity}
              />

              <Input
                inputType="number"
                name="UTBotATRLength"
                handleEdit={this.handleEdit}
                inputValue={this.state.UTBotATRLength}
              />

              <Select
                name="toTradeStraddle"
                options={["Yes", "No"]}
                handleEdit={this.handleEdit}
                inputValue={this.state.toTradeStraddle}
              />

              <Input
                name="slPct"
                inputType="number"
                handleEdit={this.handleEdit}
                inputValue={this.state.slPct}
              />

              <Input
                inputType="number"
                name="straddleSLPct"
                handleEdit={this.handleEdit}
                inputValue={this.state.straddleSLPct}
              />

              <Select
                name="tradingMode"
                options={["Paper", "Live"]}
                handleEdit={this.handleEdit}
                inputValue={this.state.tradingMode}
              />
            </form>
          </section>

          <footer className="modal-card-foot">
            <button onClick={this.handleAdd} className="button is-primary">Add</button>
            <button onClick={this.props.hideAddDialog} className="button is-danger">Cancel</button>
          </footer>
        </div>
      </div>
    );
  }
}

export default Strategy1AddDialog;