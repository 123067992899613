import { Component } from "react";

class Strategy1InfoBox extends Component {
  render() {
    return (
      <div className={this.props.show ? "modal is-active" : "modal"}>
        <div className="modal-background" onClick={this.props.handleClose}></div>

        <div className="modal-card">
          <header className="modal-card-head">
            <h5 className="modal-card-title">Condition Info (Strategy #1)</h5>
            <button className="delete" onClick={this.props.handleClose}></button>
          </header>

          <section className="modal-card-body">
            <p className="subtitle">Statistics info of instance - #{this.props.instance.id}</p>

            <h4 className="is-size-4 mb-2">Statistics:</h4>

            <table className="table is-striped is-hoverable is-fullwidth is-bordered">
              <tbody>
                <tr>
                  <th>Stopped:</th>
                  <td>{this.props.instance.stopped ? "Yes" : "No"}</td>
                </tr>
                <tr>
                  <th>Status:</th>
                  <td>{this.props.instance.status}</td>
                </tr>
                <tr>
                  <th>Entered Ins:</th>
                  <td>{this.props.instance.enteredIns}</td>
                </tr>
                <tr>
                  <th>Entered Qty:</th>
                  <td>{this.props.instance.enteredQty}</td>
                </tr>
                <tr>
                  <th>Entered Price:</th>
                  <td>{this.props.instance.enteredPrice}</td>
                </tr>
                <tr>
                  <th>LTP:</th>
                  <td>{this.props.instance.ltp}</td>
                </tr>
                <tr>
                  <th>Running P&L:</th>
                  <td>{this.props.instance.runningPnl}</td>
                </tr>
                <tr>
                  <th>Booked P&L:</th>
                  <td>{this.props.instance.bookedPnl}</td>
                </tr>
                <tr>
                  <th>Locked SL / Price At SL Lock:</th>
                  <td>{this.props.instance.lockedSL}, {this.props.instance.priceAtSLLock}</td>
                </tr>
                <tr>
                  <th>Straddle Status:</th>
                  <td>{this.props.instance.straddleStatus}</td>
                </tr>
                <tr>
                  <th>Straddle CE/PE:</th>
                  <td>{this.props.instance.straddleCE}, {this.props.instance.straddlePE}</td>
                </tr>
                <tr>
                  <th>Straddle Entry/Running Premiums:</th>
                  <td>{this.props.instance.straddleEntryPremium}, {this.props.instance.straddleRunningPremium}</td>
                </tr>
                <tr>
                  <th>Straddle Locked SL / Price At SL Lock:</th>
                  <td>{this.props.instance.straddleLockedSL}, {this.props.instance.straddlePriceAtSLLock}</td>
                </tr>
                <tr>
                  <th>Straddle Running/Booked P&Ls:</th>
                  <td>{this.props.instance.straddleRunningPnl}, {this.props.instance.straddleBookedPnl}</td>
                </tr>
              </tbody>
            </table>

            <h4 className="is-size-4 mb-3">Inputs:</h4>

            <table className="table is-striped is-hoverable is-fullwidth is-bordered">
              <tbody>
                <tr>
                  <th>Index:</th>
                  <td>{this.props.instance.index}</td>
                </tr>
                <tr>
                  <th>Expiry:</th>
                  <td>{this.props.instance.expiry}, {this.props.instance.expiryType}</td>
                </tr>
                <tr>
                  <th>Lots:</th>
                  <td>{this.props.instance.lots}</td>
                </tr>
                <tr>
                  <th>Trade Type:</th>
                  <td>{this.props.instance.tradeType}</td>
                </tr>
                <tr>
                  <th>Timeframe:</th>
                  <td>{this.props.instance.timeframe}</td>
                </tr>
                <tr>
                  <th>Candle Type:</th>
                  <td>{this.props.instance.candleType}</td>
                </tr>
                <tr>
                  <th>Condition Type:</th>
                  <td>{this.props.instance.conditionType}</td>
                </tr>
                <tr>
                  <th>Lookback Period:</th>
                  <td>{this.props.instance.lookbackPeriod}</td>
                </tr>
                <tr>
                  <th>EMA Length:</th>
                  <td>{this.props.instance.emaLength}</td>
                </tr>
                <tr>
                  <th>VIDYA Length:</th>
                  <td>{this.props.instance.vidyaLength}</td>
                </tr>
                <tr>
                  <th>MACD Fast Length:</th>
                  <td>{this.props.instance.macdFastLength}</td>
                </tr>
                <tr>
                  <th>MACD Slow Length:</th>
                  <td>{this.props.instance.macdSlowLength}</td>
                </tr>
                <tr>
                  <th>MACD Signal Length:</th>
                  <td>{this.props.instance.macdSignalLength}</td>
                </tr>
                <tr>
                  <th>UT Bot Sensitivity:</th>
                  <td>{this.props.instance.UTBotSensitivity}</td>
                </tr>
                <tr>
                  <th>UT Bot ATR Length:</th>
                  <td>{this.props.instance.UTBotATRLength}</td>
                </tr>
                <tr>
                  <th>To Trade Straddle:</th>
                  <td>{this.props.instance.toTradeStraddle}</td>
                </tr>
                <tr>
                  <th>SL %:</th>
                  <td>{this.props.instance.slPct}</td>
                </tr>
                <tr>
                  <th>Straddle SL %:</th>
                  <td>{this.props.instance.straddleSLPct}</td>
                </tr>
                <tr>
                  <th>Trading Mode:</th>
                  <td>{this.props.instance.tradingMode}</td>
                </tr>
              </tbody>
            </table>
          </section>

          <footer className="modal-card-foot"></footer>
        </div>
      </div>
    );
  }
}

export default Strategy1InfoBox;